
$('.slider-offers__wrap_sliders').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    dots:true,
    arrows:true,
    centerPadding: 30,
    prevArrow:$('.slider-offers__arrow-left'),
    nextArrow:$('.slider-offers__arrow-right'),
    responsive: [
        {
            breakpoint: 1240,
            settings: {
                arrows:false,

            }
        },


    ]
});
